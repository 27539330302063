import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function OffPageSeoPage(props) {
    return (
        <div className="container draft-view">
           <h1>Off-page SEO</h1>
           <p className="text-muted"></p>
           <p className="text-muted">Off-page SEO can boost your website ranking and grow your business through brand awareness. Although some brand awareness happens organically, you should create and manage off-page activities in order to get tangible results. The right off-page SEO strategy requires experience, time, and a reasonable course of action.</p>
           <p className="text-muted">Wescale SEO specialists can help you to achieve this in the most effective way. You will have access to free project managers who will understand your needs, optimize your budget, and organize the processes. Our team creates and implements the off-page SEO strategy in order to best achieve your business goals.</p>
           <p className="text-muted"><br /></p>
           <h2>How off-page SEO helps your business<br /></h2>
              <p> Off-page SEO means that SEO specialists can increase your relevant traffic from the Web through activities outside of the boundaries of a website. Off-page SEO is based on a knowledge of the fact that the search engines can use information from any web source to rank a website. Search engines use information about your business and website from additional sources, similar to the way that people do. Therefore, any mention of the brand or product, or any website link on the Web will be an important signal to these search engines. Off-page SEO makes these signals a mark of confidence, trust, and usefulness for both people and for search engines. That is why off-page SEO can be an effective way to increase relevant traffic to your website. </p>
              <p className="text-muted"></p>
          <p> Off-page SEO is equally good for small and medium-sized businesses, startups, e-commerce, or corporate websites. All activities depend on the purposes of your business. However, off-page optimization is a flexible SEO technique that should be set up in accordance with the specific purposes and goals of your business. If you care about your business, it is important for you to choose the right SEO. You need to avoid spammy link building and Private Blog Networks because such SEO tactics can hurt your website ranking and tarnish the reputation of your business. That is why the reasonable solution is to choose the right partner to provide quality off-page optimization. </p>
           <p className="text-muted"><br /></p>
           <h2 >Off-page SEO directions</h2><p  className="text-muted">Wescale provides complex off-page SEO service through these tactics:</p>
           <p className="text-muted"></p>
           <h5>1. Link building. outreach</h5>
           <p className="text-muted">Link building is the process of getting your own site’s link on an independent website. Inbound links also called backlinks are one of the most important factors for search engines and they drive referral traffic. The wrong link building tactics can hurt your website ranking. That is why it is smart to leave link building to professionals.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Wescale SEO specialists will build quality links to your website.</span></p>
           <p className="text-muted"></p>
           <h5>2. Guest blogging</h5>
           <p  className="text-muted">Guest blogging is when you write an article for someone else’s blog with the purpose of growing your brand, gaining exposure to a targeted audience, and generating traffic.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">The main advantage is that your content is published on third-party websites that are credible and authorized.&nbsp;</span><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Wescale SEO specialists create the most appropriate guest blogging strategies for your business.</span></p>
           <p className="text-muted"></p>
           <h5>3. Crowd marketing</h5>
        <p className="text-muted">This off-page SEO direction includes many techniques that all converge with brand mentions in relevant places on the Web. These places can be forums, social media, articles, blog comments, local business listings, question answers on websites, etc.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Although crowd marketing seems easy and inefficient you would be surprised by the results.&nbsp;</span><span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Wescale specialists follow specific rules to put quality over quantity.</span></p>
        <p className="text-muted"></p>
        <p  className="text-muted"><font face="Poppins, Helvetica, sans-serif">For best off-page SEO results we recommend the service Social Media Marketing to promote your business.</font><br /></p>

        </div>
    );
}
